<template>
  <v-row>
    <BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

    <v-col cols="12" class="d-none d-md-block">
      <ToolbarContainer :menu="menu" />
      <v-toolbar elevation="1" height="25" rounded="b">
        <Breadcrumbs />
      </v-toolbar>
    </v-col>

    <v-col cols="12" lg="12">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";
import {mapState} from "vuex";

export default {
  name: "ContratoContainer",
  components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
  computed: {
    ...mapState(["usuario"]),
  },
  data: () => ({
    menu: [],
    empresasPiloto: [1,3,7,16,19,41]
  }),
  methods: {
    montarMenu() {
      let m = [];

      if (this.permissao("contrato_listar")) {
        m.push({
          titulo: "Contratos",
          titulo_app: "Contratos",
          icon: "mdi-file-document-outline",
          to: "/contrato",
          tooltip: "Lista de contratos de clientes",
        });
      }

      if (this.permissao("contrato_assinaturas_listar") && this.empresasPiloto.includes(this.usuario.idfilial)) {
        m.push({
          titulo: "Assinaturas",
          titulo_app: "Assinaturas",
          icon: "mdi-draw-pen",
          to: "/assinaturas",
          tooltip: "Lista de cadastros de assinaturas",
        });
      }

      if (this.permissao("contrato_terceiros_listar") && (this.usuario.idfilial === 1 || this.usuario.idfilial === 3)) {
        m.push({
          titulo: "Permissão para Terceiros",
          titulo_app: "Permissão para Terceiros",
          icon: "mdi-receipt-send-outline",
          to: "/permissao_terceiros",
          tooltip: "Lista de pedidos elegíveis para lançamento de recibo de entrega para terceiro.",
        });
      }

      // if (this.permissao("contrato_logs") && (this.usuario.idfilial === 1 || this.usuario.idfilial === 3)) {
      //   m.push({
      //     titulo: "Logs",
      //     titulo_app: "Logs",
      //     icon: "mdi-history",
      //     to: "/logs",
      //     tooltip: "Logs de acessos e envios de email",
      //   });
      // }

      this.menu = m;
    },
  },
  mounted() {
    this.montarMenu();
  },
};
</script>

<style scoped>
</style>